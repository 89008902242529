/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PermissionName = 'view_logs' | 'view_telescope' | 'get_new_registration_notifications' | 'access_all_mbus' | 'manage_mbus' | 'manage_users' | 'change_user_email' | 'manage_roles' | 'manage_user_groups' | 'manage_notification_email_templates' | 'manage_exchange_rates' | 'manage_news' | 'manage_links' | 'manage_settings' | 'manage_translations' | 'manage_translation_keys' | 'manage_core_backups' | 'download_core_exports' | 'upload_core_imports' | 'download_core_imports' | 'manage_articles' | 'manage_article_transfers' | 'trigger_article_transfers' | 'manage_pictures' | 'manage_media' | 'download_article_exports' | 'upload_article_imports' | 'download_article_imports' | 'manage_sap_imports' | 'download_sap_imports' | 'upload_sap_imports' | 'manage_c4c_imports' | 'download_c4c_imports' | 'upload_c4c_imports' | 'manage_pdf_sections' | 'manage_pdf_additional_texts' | 'manage_pdf_additional_pdfs' | 'manage_pdf_email_templates' | 'manage_planning' | 'access_all_projects' | 'lock_projects' | 'use_c4c' | 'select_project_area_manager' | 'edit_dummy_articles' | 'view_sap_numbers' | 'view_material_sources' | 'use_net_pricing' | 'use_price_group_discounts' | 'use_project_discount' | 'email_documents' | 'generate_gaeb' | 'generate_purchase_orders' | 'edit_airflows' | 'use_unbalanced_airflows' | 'use_undersized_devices' | 'use_oversized_devices' | 'empty_project_zip_code' | 'work_with_comfo_plan_design';

export const PermissionName = {
    ViewLogs: 'view_logs' as PermissionName,
    ViewTelescope: 'view_telescope' as PermissionName,
    GetNewRegistrationNotifications: 'get_new_registration_notifications' as PermissionName,
    AccessAllMbus: 'access_all_mbus' as PermissionName,
    ManageMbus: 'manage_mbus' as PermissionName,
    ManageUsers: 'manage_users' as PermissionName,
    ChangeUserEmail: 'change_user_email' as PermissionName,
    ManageRoles: 'manage_roles' as PermissionName,
    ManageUserGroups: 'manage_user_groups' as PermissionName,
    ManageNotificationEmailTemplates: 'manage_notification_email_templates' as PermissionName,
    ManageExchangeRates: 'manage_exchange_rates' as PermissionName,
    ManageNews: 'manage_news' as PermissionName,
    ManageLinks: 'manage_links' as PermissionName,
    ManageSettings: 'manage_settings' as PermissionName,
    ManageTranslations: 'manage_translations' as PermissionName,
    ManageTranslationKeys: 'manage_translation_keys' as PermissionName,
    ManageCoreBackups: 'manage_core_backups' as PermissionName,
    DownloadCoreExports: 'download_core_exports' as PermissionName,
    UploadCoreImports: 'upload_core_imports' as PermissionName,
    DownloadCoreImports: 'download_core_imports' as PermissionName,
    ManageArticles: 'manage_articles' as PermissionName,
    ManageArticleTransfers: 'manage_article_transfers' as PermissionName,
    TriggerArticleTransfers: 'trigger_article_transfers' as PermissionName,
    ManagePictures: 'manage_pictures' as PermissionName,
    ManageMedia: 'manage_media' as PermissionName,
    DownloadArticleExports: 'download_article_exports' as PermissionName,
    UploadArticleImports: 'upload_article_imports' as PermissionName,
    DownloadArticleImports: 'download_article_imports' as PermissionName,
    ManageSapImports: 'manage_sap_imports' as PermissionName,
    DownloadSapImports: 'download_sap_imports' as PermissionName,
    UploadSapImports: 'upload_sap_imports' as PermissionName,
    ManageC4cImports: 'manage_c4c_imports' as PermissionName,
    DownloadC4cImports: 'download_c4c_imports' as PermissionName,
    UploadC4cImports: 'upload_c4c_imports' as PermissionName,
    ManagePdfSections: 'manage_pdf_sections' as PermissionName,
    ManagePdfAdditionalTexts: 'manage_pdf_additional_texts' as PermissionName,
    ManagePdfAdditionalPdfs: 'manage_pdf_additional_pdfs' as PermissionName,
    ManagePdfEmailTemplates: 'manage_pdf_email_templates' as PermissionName,
    ManagePlanning: 'manage_planning' as PermissionName,
    AccessAllProjects: 'access_all_projects' as PermissionName,
    LockProjects: 'lock_projects' as PermissionName,
    UseC4c: 'use_c4c' as PermissionName,
    SelectProjectAreaManager: 'select_project_area_manager' as PermissionName,
    EditDummyArticles: 'edit_dummy_articles' as PermissionName,
    ViewSapNumbers: 'view_sap_numbers' as PermissionName,
    ViewMaterialSources: 'view_material_sources' as PermissionName,
    UseNetPricing: 'use_net_pricing' as PermissionName,
    UsePriceGroupDiscounts: 'use_price_group_discounts' as PermissionName,
    UseProjectDiscount: 'use_project_discount' as PermissionName,
    EmailDocuments: 'email_documents' as PermissionName,
    GenerateGaeb: 'generate_gaeb' as PermissionName,
    GeneratePurchaseOrders: 'generate_purchase_orders' as PermissionName,
    EditAirflows: 'edit_airflows' as PermissionName,
    UseUnbalancedAirflows: 'use_unbalanced_airflows' as PermissionName,
    UseUndersizedDevices: 'use_undersized_devices' as PermissionName,
    UseOversizedDevices: 'use_oversized_devices' as PermissionName,
    EmptyProjectZipCode: 'empty_project_zip_code' as PermissionName,
    WorkWithComfoPlanDesign: 'work_with_comfo_plan_design' as PermissionName
};

