import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import {
  CcfConfiguration,
  CcfConfigurationComputationInput,
  CcfConfigurationType,
  CcfPart,
  CcfPartCategory,
  CcfPartEndModuleAdapter,
  CcfPartEndModuleType,
  CcfPartMedia,
  CcfPartModuleSize,
  CcfService as ApiCcfService,
  ErrorValidationResponse,
} from '@api';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, delay, finalize, switchMap, tap } from 'rxjs/operators';
import { DescriptionService, FormattingService, LoggerService } from '@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@env/environment';
import { ConfirmationService } from 'primeng/api';
import * as htmlToImage from 'html-to-image';
import { ConfiguratorService } from './configurator.service';

const log = new LoggerService('CCF configurator');

export interface CcFConfiguratorImages {
  side: string;
  top: string;
}

@UntilDestroy()
@Component({
  selector: 'app-ccf-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
})
export class ConfiguratorComponent implements OnInit {
  @Input() readonly: boolean;

  @Input() priceDate: string;

  @Input() withoutSideConnectionAdapters: boolean;

  @Input() configurationId: number;
  @Input() configuration: CcfConfiguration = {};

  @Input() showErrors = true;

  isLoadingConfiguration: boolean;

  isComputingConfiguration: boolean;

  isLoadingParts: boolean;

  isLoadingConfigurationPresets: boolean;

  partMedias: { [key: string]: CcfPartMedia } = {};

  modules: CcfPart[];

  frontModule: CcfPart;
  frontModules: CcfPart[];
  frontModuleAdapters: CcfPart[];

  endModule: CcfPart;
  endModules: CcfPart[];
  endModuleAdapters: CcfPart[];

  moduleSideConnectionAdapters: CcfPart[];

  validation: ErrorValidationResponse;

  showFrontModuleSelectionModal: boolean;
  showFrontModuleAdapterSelectionModal: boolean;
  showEndModuleSelectionModal: boolean;
  showEndModuleAdapterSelectionModal: boolean;
  showModuleSelectionModal: boolean;
  moduleToReplace: CcfPart;
  showConfigurationSelectionModal: boolean;

  types: { code: CcfConfigurationType; name: string }[] = [];
  booleans: { code: boolean; name: string }[] = [];

  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() computed: EventEmitter<CcfConfiguration> = new EventEmitter<CcfConfiguration>();
  @ViewChild('drawingSideView') drawingSideView: ElementRef;
  @ViewChild('drawingTopView') drawingTopView: ElementRef;
  protected _computeConfiguration$: Subject<void>;

  constructor(
    public configuratorService: ConfiguratorService,
    public apiCcfService: ApiCcfService,
    public translateService: TranslateService,
    public confirmationService: ConfirmationService,
    public formattingService: FormattingService,
    public descriptionService: DescriptionService,
  ) {
    translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      const computeConfiguration = () => {
        if (this.isLoadingParts || this.isLoadingConfigurationPresets) {
          return;
        }

        this.computeConfiguration();
      };

      this.isLoadingParts = true;
      this.configuratorService.loadParts().then(
        async (data) => {
          await this.processParts(data);

          this.isLoadingParts = false;

          computeConfiguration();
        },
        () => {
          this.isLoadingParts = false;

          computeConfiguration();
        },
      );

      this.isLoadingConfigurationPresets = true;
      this.configuratorService.loadConfigurationPresets(this.priceDate, this.withoutSideConnectionAdapters).then(
        async (data) => {
          this.configuratorService.configurationPresets = data || [];

          this.isLoadingConfigurationPresets = false;

          computeConfiguration();
        },
        () => {
          this.isLoadingConfigurationPresets = false;

          computeConfiguration();
        },
      );
    });
  }

  get CcfPartCategory() {
    return CcfPartCategory;
  }

  get CcfPartModuleSize() {
    return CcfPartModuleSize;
  }

  async ngOnInit() {
    this.types = [
      {
        code: CcfConfigurationType.Upv,
        name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.upv').toPromise(),
      },
      {
        code: CcfConfigurationType.Apv,
        name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.apv').toPromise(),
      },
    ];
    // this.types.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted

    this.booleans = [
      {
        code: false,
        name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.no').toPromise(),
      },
      {
        code: true,
        name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.yes').toPromise(),
      },
    ];
    // this.booleans.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted

    this.loadData();
  }

  async loadConfiguration() {
    if (this.isLoadingParts || this.isLoadingConfigurationPresets) {
      return;
    }

    this.isLoadingConfiguration = true;

    const completeConfiguration = () => {
      this.isLoadingConfiguration = false;

      this.loaded.emit(true);
    };

    if (this.configurationId && !this.configuration) {
      this.apiCcfService.ccfConfigurationDetails(this.configurationId).subscribe((res) => {
        this.processConfiguration(res.data);

        // If the configuration has never been saved, apply a preset
        const configurationPreset = this.configuratorService.configurationPresets.find(
          (configuration) => configuration.is_default,
        );
        if (
          configurationPreset &&
          (!this.configuration ||
            ((!this.configuration.updated_at || this.configuration.updated_at === this.configuration.created_at) &&
              !this.configuration.front_module_type &&
              (!this.configuration.modules || !this.configuration.modules.length) &&
              !this.configuration.end_module_type))
        ) {
          this.applyConfiguration(configurationPreset);
        } else {
          this.computeConfigurationForTheFirstTime();
        }

        completeConfiguration();
      }, completeConfiguration);
    } else {
      this.processConfiguration({
        ...this.configuration,
        ...{
          name:
            this.configuration && this.configuration.name
              ? this.configuration.name
              : await this.translateService
                  .get('ui.app.ccf.configurator.configurator-component-ts.demo-configuration')
                  .toPromise(),
        },
      });

      const configurationPreset = this.configuratorService.configurationPresets.find(
        (configuration) => configuration.is_default,
      );
      if (
        configurationPreset &&
        (!this.configuration ||
          (!this.configuration.front_module_type &&
            (!this.configuration.modules || !this.configuration.modules.length) &&
            !this.configuration.end_module_type))
      ) {
        this.applyConfiguration(configurationPreset);
      } else {
        this.computeConfigurationForTheFirstTime();
      }

      completeConfiguration();
    }
  }

  loadParts() {
    this.isLoadingParts = true;

    const partsHandler = async (data?: CcfPart[]) => {
      await this.processParts(data);

      this.isLoadingParts = false;

      this.loadConfiguration().then();
    };

    if (this.configuratorService.parts) {
      partsHandler(this.configuratorService.parts).then();
    } else {
      this.configuratorService.loadParts().then(partsHandler, () => {
        this.isLoadingParts = false;

        this.loadConfiguration().then();
      });
    }
  }

  loadConfigurationPresets() {
    this.isLoadingConfigurationPresets = true;

    if (!this.configuratorService.configurationPresets) {
      this.configuratorService.loadConfigurationPresets(this.priceDate, this.withoutSideConnectionAdapters).then(
        (data) => {
          this.configuratorService.configurationPresets = data || [];

          this.isLoadingConfigurationPresets = false;

          this.loadConfiguration().then();
        },
        () => {
          this.isLoadingConfigurationPresets = false;

          this.loadConfiguration().then();
        },
      );
    } else {
      this.isLoadingConfigurationPresets = false;

      this.loadConfiguration().then();
    }
  }

  loadData() {
    this.loadParts();
    this.loadConfigurationPresets();
    this.loadConfiguration().then(); // this will wait for parts, modules and presets anyway
  }

  async processParts(data?: CcfPart[]) {
    this.configuratorService.parts = data || [];

    this.partMedias = {};
    this.configuratorService.parts.forEach((part) => {
      if (part.media) {
        this.partMedias[part.code] = part.media;
      }
    });

    this.modules = this.configuratorService.parts.filter((part) => part.category === CcfPartCategory.Module);

    this.frontModules = this.configuratorService.parts.filter((part) => part.category === CcfPartCategory.FrontModule);
    this.frontModules.unshift({
      name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.no-module').toPromise(),
    });

    this.frontModuleAdapters = this.configuratorService.parts.filter(
      (part) => part.category === CcfPartCategory.FrontModuleAdapter,
    );
    this.frontModuleAdapters.unshift({
      name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.no-adapter').toPromise(),
    });

    this.endModules = this.configuratorService.parts.filter((part) => part.category === CcfPartCategory.EndModule);
    this.endModules.unshift({
      name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.no-module').toPromise(),
    });

    this.endModuleAdapters = this.configuratorService.parts.filter(
      (part) => part.category === CcfPartCategory.EndModuleAdapter,
    );
    this.endModuleAdapters.unshift({
      name: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.no-adapter').toPromise(),
    });

    this.moduleSideConnectionAdapters = this.configuratorService.parts.filter(
      (part) => part.category === CcfPartCategory.ModuleSideConnectionAdapter,
    );
  }

  convertConfigurationToConfigurationInput() {
    const configuration: CcfConfigurationComputationInput = {
      ...this.configuration,
      side_connection_adapters:
        this.configuration && this.configuration.side_connection_adapters
          ? { ...this.configuration.side_connection_adapters }
          : {},
      modules:
        this.configuration && this.configuration.modules
          ? this.configuration.modules.map((module) => ({
              ...module,
              size: module.size || CcfPartModuleSize.NUMBER_2, // TODO: Why?
            }))
          : [],
      length: this.computeTotalLength(),
    };

    return configuration;
  }

  processConfiguration(configuration?: CcfConfiguration) {
    if (!configuration) {
      configuration = this.configuration || {};
    }

    if (configuration.inside_insulation !== undefined) {
      configuration.inside_insulation = !!configuration.inside_insulation;
    }

    if (configuration.outside_insulation !== undefined) {
      configuration.outside_insulation = !!configuration.outside_insulation;
    }

    if (!configuration.modules) {
      configuration.modules = [];
    }

    if (this.frontModules) {
      this.frontModule = this.frontModules.find(
        (module) =>
          (!configuration.front_module_type && !module.type) ||
          (configuration.front_module_type && module.type === configuration.front_module_type),
      );
    }

    if (this.frontModuleAdapters && this.frontModuleAdapters.length) {
      const optionFrontModuleAdapter = this.frontModuleAdapters.find((module) => !module.code);
      optionFrontModuleAdapter.size = this.frontModule ? this.frontModule.size : null;
    }

    if (this.endModules) {
      this.endModule = this.endModules.find(
        (module) =>
          (!configuration.end_module_type && !module.type) ||
          (configuration.end_module_type && module.type === configuration.end_module_type),
      );
    }

    if (this.endModuleAdapters && this.endModuleAdapters.length) {
      const optionEndModuleAdapter = this.endModuleAdapters.find((module) => !module.code);
      optionEndModuleAdapter.size = this.endModule ? this.endModule.size : null;
    }

    this.configuration = {
      ...configuration,
      inside_insulation: configuration.inside_insulation || false,
      outside_insulation: configuration.outside_insulation || false,
      side_connection_adapters: configuration.side_connection_adapters || {},
      length: this.computeTotalLength(),
    };
  }

  onModuleSideConnectionAdaptersChange(code: string, event: Event) {
    if (
      !event ||
      !event.target ||
      !this.configuration ||
      !this.configuration.side_connection_adapters ||
      !this.configuration.side_connection_adapters[code]
    ) {
      return;
    }

    if (this.configuration.side_connection_adapters[code] < 0) {
      this.configuration.side_connection_adapters[code] = 0;
    }

    if (this.configuration.side_connection_adapters[code] > 12) {
      this.configuration.side_connection_adapters[code] = 12;
    }
  }

  addModule(moduleToAdd: CcfPart, moduleToDelete: CcfPart = null) {
    if (!this.configuration) {
      return;
    }

    if (!this.configuration.modules) {
      this.configuration.modules = [];
    }

    moduleToAdd = { ...moduleToAdd };

    const index = moduleToDelete ? this.configuration.modules.indexOf(moduleToDelete) : -1;
    if (index !== -1) {
      this.configuration.modules.splice(index, 1, moduleToAdd);
    } else {
      this.configuration.modules.push(moduleToAdd);
    }

    this.computeConfiguration();
  }

  async deleteModule(module: CcfPart) {
    if (!this.configuration || !this.configuration.modules) {
      return;
    }

    this.confirmationService.confirm({
      header: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.delete-module')
        .toPromise(),
      message: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.are-you-sure-you-want-to-delete-module')
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.cancel')
        .toPromise(),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const index = this.configuration.modules.indexOf(module);
        if (index != -1) {
          this.configuration.modules.splice(index, 1);
        }

        this.computeConfiguration();
      },
    });
  }

  applyFrontModuleSelection(result: CcfPart) {
    if (result) {
      this.processConfiguration({
        ...this.configuration,
        front_module_type: result.type,
        front_module_adapter: null,
      });

      this.computeConfiguration();
    }
  }

  applyFrontModuleAdapterSelection(result: CcfPart) {
    if (result) {
      this.processConfiguration({
        ...this.configuration,
        front_module_adapter: result.size as CcfPartEndModuleAdapter,
      });

      this.computeConfiguration();
    }
  }

  applyEndModuleSelection(result: CcfPart) {
    if (result) {
      this.processConfiguration({
        ...this.configuration,
        end_module_type: result.type,
        end_module_adapter: null,
      });

      this.computeConfiguration();
    }
  }

  applyEndModuleAdapterSelection(result: CcfPart) {
    if (result) {
      this.processConfiguration({
        ...this.configuration,
        end_module_adapter: result.size as CcfPartEndModuleAdapter,
      });

      this.computeConfiguration();
    }
  }

  applyModuleSelection(result: CcfPart) {
    if (result) {
      this.addModule(result, this.moduleToReplace);
    }
  }

  applyConfiguration(result: CcfConfiguration) {
    this.processConfiguration({
      ...this.configuration,
      ...result,
    });

    this.computeConfiguration();
  }

  async getDrawingImage(elementRef: ElementRef) {
    if (!elementRef) {
      return;
    }

    const element = elementRef.nativeElement;

    // the padding and height are relevant for the measurements position
    const paddingLeft = parseInt(window.getComputedStyle(element).paddingLeft) || 0;

    // Give room for padding; also on the right side
    let width = paddingLeft;

    const filter = (node: HTMLElement) => {
      return !node.classList || !node.classList.contains('d-print-none');
    };

    // Sum children widths to trim the full width element
    for (let child of element.children) {
      if (!filter(child)) {
        continue;
      }
      width += child.scrollWidth || 0;
    }

    const options = { width, filter, pixelRatio: 2 };

    return htmlToImage.toPng(element, options).catch(function (error) {
      // fallback to SVG; transform on the server
      return htmlToImage.toSvg(element, options);
    });
  }

  async getDrawingImages() {
    return Promise.all([this.getDrawingImage(this.drawingSideView), this.getDrawingImage(this.drawingTopView)]).then(
      (dataUrls) => {
        if (dataUrls.length < 2 || !dataUrls[0] || !dataUrls[1]) {
          throw 'Could not retrieve images';
        }

        return {
          side: dataUrls[0],
          top: dataUrls[1],
        };
      },
    );
  }

  async clearConfiguration() {
    /*
    const draw = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      img.style.border = '1px solid red';
      document.body.appendChild(img);
    };

    this.getDrawingImages().then(res => {
      draw(res.side);
      draw(res.top);
    });

    return;
    */

    this.confirmationService.confirm({
      header: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.clear-configuration')
        .toPromise(),
      message: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.are-you-sure-you-want-to-clear-configuration')
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.ccf.configurator.configurator-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService
        .get('ui.app.ccf.configurator.configurator-component-ts.cancel')
        .toPromise(),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.processConfiguration({
          ...this.configuration,
          ...{
            front_module_type: null,
            front_module_adapter: null,
            modules: [],
            end_module_type: null,
            end_module_adapter: null,
          },
        });

        this.computeConfiguration();
      },
    });
  }

  async applyConfigurationSelection(result: CcfConfiguration) {
    if (result) {
      this.confirmationService.confirm({
        header: await this.translateService
          .get('ui.app.ccf.configurator.configurator-component-ts.select-preset-configuration')
          .toPromise(),
        message: await this.translateService
          .get('ui.app.ccf.configurator.configurator-component-ts.are-you-sure-you-want-to-select-preset-configuration')
          .toPromise(),
        acceptLabel: await this.translateService
          .get('ui.app.ccf.configurator.configurator-component-ts.ok')
          .toPromise(),
        rejectLabel: await this.translateService
          .get('ui.app.ccf.configurator.configurator-component-ts.cancel')
          .toPromise(),
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-secondary',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.applyConfiguration(result);
        },
      });
    }
  }

  computeConfigurationForTheFirstTime() {
    this.apiCcfService.ccfCompute(this.convertConfigurationToConfigurationInput()).subscribe(
      (res) => {
        this.processConfiguration({
          ...this.configuration,
          ...res.data,
        });

        this.computed.emit(this.configuration);
      },
      (res) => (this.validation = res && res.error ? res.error : {}),
    );
  }

  computeConfiguration() {
    if (!this._computeConfiguration$) {
      this._computeConfiguration$ = new Subject<void>();
      this._computeConfiguration$
        .pipe(
          tap(() => (this.isComputingConfiguration = true)),
          debounceTime(200),
          switchMap(() => {
            return this.apiCcfService.ccfCompute(this.convertConfigurationToConfigurationInput());
          }),
          delay(200),
          finalize(() => (this.isComputingConfiguration = false)),
        )
        .subscribe(
          (res) => {
            this.processConfiguration({
              ...this.configuration,
              id: null, // configuration changed
              // overwrite only server side computed values
              ...res.data,
            });

            this.isComputingConfiguration = false;

            this.computed.emit(this.configuration);
          },
          (res) => (this.validation = res && res.error ? res.error : {}),
        );
    }
    this._computeConfiguration$.next();
  }

  resetErrorsAndWarnings(key: string, deep = false) {
    if (this.validation) {
      if (this.validation.errors) {
        delete this.validation.errors[key];

        if (deep) {
          Object.keys(this.validation.errors).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.errors[keyPotential];
            }
          });
        }
      }

      if (this.validation.warnings) {
        delete this.validation.warnings[key];

        if (deep) {
          Object.keys(this.validation.warnings).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.warnings[keyPotential];
            }
          });
        }
      }

      if (!this.validation.errors || !Object.keys(this.validation.errors).length) {
        this.validation.message = '';
      }
    }
  }

  computeTotalLength() {
    if (!this.configuration) {
      return;
    }

    let totalLength = 0;

    switch (this.configuration.front_module_type) {
      case CcfPartEndModuleType.Up:
      case CcfPartEndModuleType.Down:
        totalLength += environment.ccfDimensions.edgeModuleLength;
        switch (this.configuration.front_module_adapter) {
          case CcfPartEndModuleAdapter.NUMBER_150:
          case CcfPartEndModuleAdapter.NUMBER_125:
            totalLength += environment.ccfDimensions.adapterLength;
            break;
        }
        break;
      case CcfPartEndModuleType.End:
        totalLength += environment.ccfDimensions.endModuleLength;
        break;
    }

    if (this.configuration.modules) {
      this.configuration.modules.forEach((module) => {
        totalLength += module.size * environment.ccfDimensions.unitLength;
      });
    }

    switch (this.configuration.end_module_type) {
      case CcfPartEndModuleType.Up:
      case CcfPartEndModuleType.Down:
        totalLength += environment.ccfDimensions.edgeModuleLength;
        switch (this.configuration.end_module_adapter) {
          case CcfPartEndModuleAdapter.NUMBER_150:
          case CcfPartEndModuleAdapter.NUMBER_125:
            totalLength += environment.ccfDimensions.adapterLength;
            break;
        }
        break;
      case CcfPartEndModuleType.End:
        totalLength += environment.ccfDimensions.endModuleLength;
        break;
    }

    return totalLength;
  }
}
